import React from "react";
import { css } from "@emotion/core";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { font, mediaQuery, smartPhone } from "../constants/styles";

const wrapper = css`
  width: 80%;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: ${font.textSize};
  line-height: 2;
  ${mediaQuery[smartPhone]} {
    width: 90%;
    padding-top: 90px;
  }
`;

const thanks = css`
  ${mediaQuery[smartPhone]} {
    padding-bottom: 12px;
  }
`


export default function Thanks() {
  const title = "お問い合わせ";

  return (
    <Layout>
      <Head props={title} />
      <div css={wrapper}>
        <div css={thanks}>お問い合わせありがとうございました。</div>
        <div>
          回答にはお時間をいただく場合がありますので、お急ぎの方はお電話（0742-34-7991）にてお問合せください。
        </div>
      </div>
    </Layout>
  );
}
